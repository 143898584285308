import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import MenuPages from "./MenuPages.js"
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';

import { useContext } from 'react';
import LanguageContext from "./../languageContext";


const NavMenu = (props) => {

  const { language, setLanguage } = useContext(LanguageContext);

  function cambiaLingua(theLang) {
    console.log("language " + theLang);
    setLanguage(theLang);
  };

  const [anchorElNav, setAnchorElNav] = useState(null); // questo mi dice a che ELEMENT si aggrappa il MENU
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpenNavMenu = (event) => {
    // setAnchorElNav(event.currentTarget); //dice al menu a che elemento agganciarsi
    setMenuOpen(current => !current);
  };

  /*
  useEffect(() => {
    //console.log(menuOpen); // ATTENZIONE: la chiamata "setMenuOpen" è asincrona (non immediata) => useEffect la aggiorna
  }, [menuOpen]);*/


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  //--------per far scomparire BARRA MENU on scrolling-----

  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

  //--------- fine -----------------------


  return (
    <>
      <div className="lingue"><span onClick={() => cambiaLingua("it")}>IT</span><span onClick={() => cambiaLingua("en")}>EN</span></div>
      <HideOnScroll {...props}>
        <AppBar position="sticky" className='AppBar'> {/* era static -> se la vuoi presente usa sticky anziche fixed, che mangia contenuto*/}
          <Container maxWidth="lg" sx={{ display: 'flex', padding: 0 }}>

            {/* MENU MOBILE */}
            {/* 
            <Box sx={{ flex: 0.5, display: { xs: 'flex', md: 'none' }, backgroundColor: '#4894b1' }}>
              <MenuIcon onClick={apriloChiudilo} />
              <div id="bingo" style={{ position: 'fixed', top: '60px', left: '-300px' }}>
                <MenuPages Mode="mobile" Lingua={language} />
              </div>
            </Box>*/}

            <MenuIcon onClick={() => window["menuOpenClose"]()} sx={{ display: { xs: 'block', md: 'none' } }} className='menuIcon' />

            {/* MENU DESKTOP , display: { xs: 'none', md: 'flex' } */}
            <div id="boxMenu">
              <MenuPages Mode="desktop" Lingua={language} />
            </div>

            {/* LOGO */}
            <div id="boxLogo">
              <p style={{ width: '100%', textAlign: 'right' }}>LOGO</p>
            </div>

          </Container>
        </AppBar>
      </HideOnScroll>
    </>
  );

}

export default NavMenu;
