import React from 'react';



const Catalogo = () =>{

    return(
        <div>So I'm Locked in CATALOGO component</div>
    );
}


export default Catalogo;