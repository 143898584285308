import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel'; // Il Carousel è preso da => https://learus.github.io/react-material-ui-carousel/ 
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Paper } from '@mui/material'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Container } from '@mui/material';

import Modal from '@mui/material/Modal';

import useMediaQuery from '@mui/material/useMediaQuery';

import Fade from '@mui/material/Fade';


const Slideshow = (props) => {

    const Desktop = useMediaQuery('(min-width:1000px)'); // restituisce boolean
    const Tablet = useMediaQuery('(min-width:650px)');
    /*const Mobile = useMediaQuery('(min-width:100px)');*/

    const [tipo, setTipo] = useState("slideShow");
    const [settings, setSettings] = useState("standard");
    const [colonne, setColonne] = useState("3");
    const [showSlideshows, setShowSlideshows] = useState(false);
    const [opened, setOpened] = useState(false);
    const [photoToOpen, setPhotoToOpen] = useState("");
    const handleOpenModal = (currentPhoto) => {
        //console.log("handleOpenModal " + currentPhoto);
        currentPhoto = currentPhoto.replace("medium_", "").replace("square_", "").replace("thumb_", "");
        setPhotoToOpen(currentPhoto);
        setOpened(true);
    }
    const handleCloseModal = () => setOpened(false);

    const [items, setItems] = useState([ // array di partenza, se si passa ID slideshow carica il nuovo
        {
            src: 'https://cbi-europe.it//public/banner/fratesi_copertina.jpg',
            name: 'Slide 2',
            description: 'Slide 2 description',
            key: 2,
        },
        {
            src: 'https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg?w=2000',
            name: 'Slide 1',
            description: 'Slide 1 description',
            key: 1,
        },
        {
            src: 'https://cbi-europe.it//public/banner/afi_tech_park_phase_2ante.jpg',
            name: 'Slide 3',
            description: 'Slide 3 description',
            key: 3,
        },
    ]);


    useEffect(() => {

        setShowSlideshows(false);

        if (props.id !== undefined) {

            fetch("api/slideShowData/" + props.id)
                .then((results) => { return results.json(); })
                .then(data => {

                    //tutti i dati slideshow => data.id    data.title   data.images   data.type   data.settings   data.showTitle   data.imagesList
                    //console.log("carico Slideshow ID " + data.id + " -> tipo " + data.type + ", settings " + data.settings);

                    setTipo(data.type);
                    if (data.settings !== null && data.settings.indexOf("|") >= 0) {
                        setSettings(data.settings.split('|')[0]);
                        setColonne(parseInt(data.settings.split('|')[1]));
                    }

                    setItems(data.imagesList); //data è lista IDENTICA a items (generata sul controller)

                    setShowSlideshows(true); //solo ora mostra slideshow

                })
        }

    }, [props.id]);

    if (props.mode === "CMS_preview") { //--------solo per preview nell'elenco slideshow -> gestione Immagini
        return (
            <>
                {items.map((item) => (

                    <img key={item.key} src={item.src.replace("/images/medium_", "/images/").replace("/images/", "/images/square_")} alt="" />
                ))}
            </>
        );
    }
    else {


        if (window.location.pathname === "/Home" || window.location.pathname === "/") { // ------------------------HOME PAGE SLIDESHOW

            return (
                <>
                    <Container maxWidth="xl" disableGutters>
                        <Carousel
                            height="600px"
                            NextIcon={<ArrowCircleRightIcon style={{ color: 'gold' }} />}
                            PrevIcon={<ArrowCircleLeftIcon />}
                            navButtonsAlwaysVisible="true">
                            {
                                items.map((item, i) => <Item key={i} item={item} />)
                            }
                        </Carousel>
                    </Container>
                </>
            );
        }
        else {
            if (tipo === "slideShow") {
                return (  // ------------------------------------------------------------SLIDESHOW dentro PAGE
                    <>
                        <Container maxWidth="xl" sx={{ visibility: showSlideshows ? "visible" : "hidden" }} disableGutters>
                            <Carousel
                                height="300px"
                                navButtonsAlwaysVisible="false">
                                {
                                    items.map((item, i) => <Item key={i} item={item} />)
                                }
                            </Carousel>
                        </Container>
                    </>
                );
            }
            else {
                return (  // ------------------------------------------------------------IMAGE LIST dentro PAGE (https://mui.com/material-ui/react-image-list/)
                    <>
                        <ImageList variant={settings} sx={{ visibility: showSlideshows ? "visible" : "hidden" }} cols={Desktop ? colonne : Tablet ? 2 : 1} gap={8}>
                            {items.map((item) => (
                                <ImageListItem key={item.key}>
                                    <img
                                        src={item.src}
                                        srcSet={item.src}
                                        alt={item.name}
                                        loading="lazy"
                                        onClick={() => handleOpenModal(item.src)} />
                                    <ImageListItemBar title={getTitle(item.name, "title")} subtitle={getTitle(item.name, "subTitle")} sx={{ visibility: (item.name !== "" && item.name !== "n/a") ? "visible" : "hidden" }} />
                                </ImageListItem>

                            ))}
                        </ImageList>
                        {/* il Modal apre popup con Immagine - puoi provare ad usare transition (https://mui.com/material-ui/transitions/) */}
                        <Modal sx={{ backgroundColor: '#000000d6', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={opened} onClick={handleCloseModal} >
                            <div className="bigPhotoModal">
                                <img src={photoToOpen} alt="" />
                            </div>
                        </Modal>
                    </>
                );
            }
        }
    }
}



function Item(props) {
    return (
        <Paper elevation={0} className="slideshowInside" style={{ height: '100%', margin: '0', backgroundImage: 'url(' + props.item.src + ')' }}> {/* PAPER è contenitore con Elevation*/}
            <div className='captionTitle'>{getTitle(props.item.name, "title")}</div>
            <div className='captionDesc'>{getTitle(props.item.name, "subTitle")}</div>
            {/*<Button variant="contained">Read More</Button>*/}
        </Paper>
    )
}


function getTitle(title, mode) {
    if (title === "n/a") {
        return "";
    }
    else {
        if (mode === "subTitle") {
            if (title.indexOf("|") >= 0) {
                return title.split('|')[1].trim();
            }
            else { return ""; }
        }
        else if (mode === "title") {
            return title.split('|')[0].trim();
        }
    }

}



export default Slideshow;