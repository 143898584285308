import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useContext } from 'react';
import LanguageContext from "./../languageContext";

import NavMenu from "./NavMenu.js";


const Menu = (props) => {

    const [menuArray, setMenuArray] = useState([]);

    let showCat = false;
    let catAlreadyShown = false;

    // eslint-disable-next-line no-unused-vars
    const { language, setLanguage } = useContext(LanguageContext);

    const fixedPages = [
        { id: '1000', titolo: 'Home', categoria: 'n/a', url: language === "it" ? "Home" : "Home?lang=" + language },
        //{ id: '1001', titolo: 'Counter', url: 'Counter', },
        //{ id: '1002', titolo: 'Test', url: 'Test', },
        { id: '1003', titolo: 'Pagine', categoria: 'n/a', url: 'cms/Pagine', },
        { id: '1004', titolo: 'Immagini', categoria: 'n/a', url: 'cms/Immagini', },
    ];

    async function getPage() {
        await fetch("api/getMenu/" + props.Lingua)
            .then(response => response.json())
            .then(pagine => {

                var newArray = fixedPages.concat(pagine); //creo NUOVO array => fixedPages + pagine

                setMenuArray(newArray);
            });
    }

    useEffect(() => {
        console.log("menuPages use effect " + props.Mode); //viene chiamato 2 volte, da desktop e da mobile 

        getPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <ul className="menuUl">
            {
                (menuArray != null) ? menuArray.map((current, index, element) => {

                    // logica per mostrare o meno CATEGORIA di navigazione e relative SOTTOPAGINE
                    if (current.categoria === "n/a") { showCat = false; } //pagine fixed + pagine senza categoria
                    else if (index + 1 === menuArray.length) { catAlreadyShown = true; } //ultimo elemento: forzo di NON mostrare stringa cat, che sia aperta o meno
                    else if (showCat) {
                        catAlreadyShown = true;
                        if (current.categoria !== element[index - 1].categoria) { //se cat già aperta (showCat), chiudo se nuova categoria è diversa dalla precedente
                            showCat = false; catAlreadyShown = false;
                        }
                    }
                    else if (current.categoria === element[index + 1].categoria) {//se categoria corrente è uguale alla successiva, apro cat
                        showCat = true;
                    }

                    // console.log("current " + props.Mode + " > " + current.titolo + " - showCat " + showCat + " | catAlreadyShown " + catAlreadyShown);

                    return (

                        showCat ?
                            (!catAlreadyShown) ?

                                <li key={props.Mode + "_cat_" + current.id}>{current.categoria}
                                    <ul>
                                        {
                                            menuArray.map((cur) => { //cerco sottopagine
                                                if (current.categoria === cur.categoria) {
                                                    return (
                                                        <Link key={props.Mode + "_" + cur.id} to={(cur.url != null) ? "/" + cur.url : "/page/" + cur.id} >
                                                            <li id={"menu_" + props.Mode + "_" + cur.id}>{cur.titolo}</li>
                                                        </Link>
                                                    );
                                                }
                                                else { return (null) }
                                            }
                                            )
                                        }
                                    </ul>
                                </li>

                                : ""

                            :

                            <Link key={props.Mode + "_" + current.id} to={(current.url != null) ? "/" + current.url : "/page/" + current.id} >
                                <li id={"menu_" + props.Mode + "_" + current.id} onClick={() => window["menuOpenClose"]()}>{current.titolo}</li>
                            </Link>
                    )
                })
                    : "carico pagine menu"
            }
        </ul>
    );
}

export default Menu;
