import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container } from '@mui/material';



const Home = (args) => {

  const [searchParams] = useSearchParams();
  const currentLang = searchParams.get('lang'); //passo lang su url => /Home?lang=en

  return (
    <>
      <Container maxWidth="lg">
        <h1>Home Page {(currentLang === "en") ? " - we are multilanguage" : ""}</h1>
      </Container>

    </>
  );
}

export default Home;