import React, { useState, useEffect } from 'react';
import DandoEditor from './cms/DandoEditor';
import { useContext } from 'react';
import LanguageContext from "./../languageContext";


const Counter = () => {

  const {language, setLanguage} = useContext(LanguageContext);

  return (
<>
<h1>Counter - la lingua è: {language}</h1>;
<DandoEditor/>
</>

  );
};

export default Counter;
