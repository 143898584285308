import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import Container from '@mui/material/Container';


const Footer = () => {


    return (
        <Paper sx={{ position: 'relative', bottom: 0, left: 0, right: 0, margin: "180px 0 0 0", backgroundColor: "lightgray" }} elevation={1}>
            <Container maxWidth="lg" sx={{ display: 'flex', padding: 0 }}>
                <Box maxWidth="lg" style={{ width: '100%', backgroundColor: '#ebefef', padding: '20px 0' }}>Sono il FOOTER</Box>
            </Container>
        </Paper>
    );
}


export default Footer;