import React, { useState, useEffect, useRef } from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import Button from '@mui/material/Button';


const rows = [
    { id: 1, col1: 'Hello', col2: 'World' },
    { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
    { id: 3, col1: 'MUI', col2: 'is Amazing' },
];

const columns = [
    { field: 'col1', headerName: 'Column 1', width: 150 },
    { field: 'col2', headerName: 'Column 2', width: 150 },
];





const Test = () => {

    const [cojoApi, setCojoApi] = useState("vuoto");
    const cojoneApi = () => {
        console.log("tools");

        fetch("/api/tools")
            .then(function (body) {
                return body.text();
            }).then(function (data) {
                console.log("data " + data);
                setCojoApi(data);
            });
    }

    const [remoteApi, setRemoteApi] = useState("vuoto");
    const talkToRemoteApi = () => {
        setRemoteApi("inizio caricamento");

        fetch("/api/talktoremote")
            .then(function (body) {
                return body.text();
            }).then(function (data) {
                console.log("data " + data);
                setRemoteApi(data);
            });
    }



    const [fileSelected, setFileSelected] = useState();

    const saveFileSelected = (e) => {
        //in case you wan to print the file selected
        //console.log(e.target.files[0]);
        setFileSelected(e.target.files[0]);
    };

    const importFile = async (e) => {
        const formData = new FormData();
        formData.append("Title", "titolo da produrre");
        formData.append("ImageFile", fileSelected);

        //invio
        fetch('api/uploadimage',
            {
                method: "POST",
                body: formData
            })
            .then(response => response.text())
            .then(data => {
                console.log("uploading " + data)
            });

    };



    return (
        <>
            <h1>Sono TEST</h1>

            <div style={{ height: 300, width: '100%' }}>
                <DataGrid rows={rows} columns={columns} />
            </div>

            <br /><br />
            <div>{cojoApi}</div>
            <button onClick={cojoneApi}>API controller PAGES - semplice stringa</button>

            <br /><br />
            <div>{remoteApi}</div>
            <button onClick={talkToRemoteApi}>API controller PAGES - consultare API esterna a dominio</button>

            <br /><br />
            <input type="file" onChange={saveFileSelected} />
            <input type="button" value="upload" onClick={importFile} />
        </>
    );
}

export default Test;