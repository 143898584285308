import Counter  from "./components/Counter";
import Home  from "./components/Home";
import Test  from "./components/Test";
import Pagine  from "./components/cms/Pagine";
import Immagini  from "./components/cms/Immagini";
import Loggin  from "./components/cms/Loggin";
import Page  from "./components/Page";
import Slideshow from './components/Slideshow';

const AppRoutes = [
    {
        index: true,
        element: (
            <>
            <Slideshow/>
            <Home />
            </>
          )
    },
    {
        path: '/home',
        element: (
            <>
            <Slideshow/>
            <Home />
            </>
          )
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/test',
        element: <Test />
    },
    {
        path: '/cms/pagine',
        element: <Pagine />
    },
    {
        path: '/cms/immagini',
        element: <Immagini />
    },
    {
        path: '/cms/loggin',
        element: <Loggin />
    },
    {
        path: '/page',
        element: <Page />
    }
];

export default AppRoutes;
