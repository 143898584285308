import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ImageIcon from '@mui/icons-material/Image';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';

// basato sull'editor => npm install --save @ckeditor/ckeditor5-react @ckeditor/ckeditor5-build-classic --legacy-peer-deps  

const DandoEditor = forwardRef((props, ref) => {

  const initialData = "inserisci testo pagina";
  const [sorgente, setSorgente] = useState("");
  const [editor, setEditor] = useState(undefined);
  const [sourceBlockVisibility, setSourceBlockVisibility] = useState(false);
  const [imagesList, setImagesList] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentImg, setCurrentImage] = useState(false);
  const [showExtraButton, setShowExtraButton] = useState(false);

  function fillSorgente() {

    setSorgente(editor.getData());

    sourceBlockVisibility ? setSourceBlockVisibility(false) : setSourceBlockVisibility(true)

  }

  const handleSorgenteChange = event => {
    setSorgente(event.target.value);
  };

  function passTextToEditor() {
    editor.setData(sorgente);
  }

  function fillImages(e) {

    var whoCalls = e.target.getAttribute("id");

    if (whoCalls === "aggiornaBtn" || imagesList === "") {

      fetch("api/allImages")
        .then((results) => {
          return results.json();
        })
        .then(data => {

          setShowExtraButton(true);

          setImagesList(() => {
            return (
              <>
                {data.map((item, index) => {
                  return (
                    <figure key={'fig_' + index}>
                      <img src={"/images/thumb_" + item.imageName + ""} onClick={insertImage} alt="" />
                      <figcaption>{item.title.replace("n/a", "").split("|")[0].trim()}</figcaption>
                    </figure>
                  );
                })}

              </>
            );
          });
        })
    }
    else { // chiudo lista immagini

      setImagesList("");
      setShowExtraButton(false);
    }
  }

  const insertImage = (e) => {

    setCurrentImage(e.target.getAttribute('src'));
    setOpenDialog(true);   //https://mui.com/material-ui/react-dialog/
  }

  function handleListItemClick(imgType) {

    setOpenDialog(false);

    if (imgType !== "") {

      var imgPath = currentImg.toString().split("/images/thumb_")[1];
      let imgToInsert;

      switch (imgType) {
        case "full":
          imgToInsert = "<img src='/images/" + imgPath + "' alt='' />";
          break;
        case "medium":
          imgToInsert = "<img src='/images/medium_" + imgPath + "' alt='' />";
          break;
        case "square":
          imgToInsert = "<img src='/images/square_" + imgPath + "' alt='' />";
          break;
        case "thumb":
          imgToInsert = "<img src='/images/thumb_" + imgPath + "' alt='' />";
          break;
        default:
      }

      //aggiungo l'immagine all'Editor
      var newEditorData = editor.getData() + imgToInsert;
      editor.setData(newEditorData);
    }
  }

  function redirectToGestioneImmagini() {
    window.open("/cms/Immagini", '_blank')
  }


  // Questo serve per REFERENZIARE dei metodi da utilizzare si altro componente (Pagine.js) - ho creato 2 metodi per get/set editor data
  useImperativeHandle(ref, () => ({
    getTheEditorData, setTheEditorData
  }));

  const getTheEditorData = () => {
    return editor.getData();
  };

  const setTheEditorData = (myData) => {
    editor.setData(myData);
  };

  return (
    <>

      <CKEditor editor={ClassicEditor} data={initialData} // per altezza editor: classe css => .ck-editor__editable {min-height: 300px;}
        onReady={editor => {
          setEditor(editor); // You can store the "editor" and use when it is needed.
        }}
        onChange={(event, editor) => {
          //const data = editor.getData();
        }}
        onBlur={(event, editor) => {
        }}
        onFocus={(event, editor) => {
        }}
      />

      <Button variant="outlined" size="small" onClick={fillSorgente}>Codice Sorgente</Button>
      <Button variant="outlined" size="small" onClick={fillImages}>Inserisci immagine</Button>
      <Button variant="text" size="small" sx={{ display: showExtraButton ? "inline-block" : "none", margin: "0 10px 0 20px", color: "gray" }} onClick={redirectToGestioneImmagini}>+ Aggiungi Immagine</Button>
      <Button variant="text" size="small" sx={{ display: showExtraButton ? "inline-block" : "none", color: "gray" }} onClick={fillImages} id="aggiornaBtn">Aggiorna</Button>

      <div className="editorListaImmagini">{imagesList}</div>

      <Dialog open={openDialog} className="imageDialogSelector">

        <DialogTitle className="DialogTitle">Scegli formato <span onClick={() => handleListItemClick("")}>X</span></DialogTitle>

        <List sx={{ m: 0, p: 0 }}>
          <ListItem disableGutters>

            <ListItemButton key="list_1" onClick={() => handleListItemClick("full")}>
              <ListItemAvatar><Avatar sx={{ backgroundColor: "#eee" }}><ImageIcon color="primary" /></Avatar></ListItemAvatar>
              <ListItemText primary="Full Size" secondary="HD" />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>

            <ListItemButton key="list_2" onClick={() => handleListItemClick("medium")}>
              <ListItemAvatar><Avatar sx={{ backgroundColor: "#eee" }}><ImageIcon color="primary" sx={{ fontSize: "medium" }} /></Avatar></ListItemAvatar>
              <ListItemText primary="Medium Size" secondary="< 1000px" />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>

            <ListItemButton key="list_2" onClick={() => handleListItemClick("thumb")}>
              <ListItemAvatar><Avatar sx={{ backgroundColor: "#eee" }}><ImageIcon color="primary" sx={{ fontSize: "small" }} /></Avatar></ListItemAvatar>
              <ListItemText primary="Thumbnail" secondary="200px" />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>

            <ListItemButton key="list_2" onClick={() => handleListItemClick("square")}>
              <ListItemAvatar><Avatar sx={{ backgroundColor: "#eee" }}><ImageIcon color="primary" sx={{ fontSize: "small" }} /></Avatar></ListItemAvatar>
              <ListItemText primary="Thumbnail Square" secondary="200 x 200" />
            </ListItemButton>
          </ListItem>

        </List>
      </Dialog>

      <div className='editorSourceCodeBlock' style={{ display: sourceBlockVisibility ? "block" : "none" }} >
        <textarea value={sorgente} onChange={handleSorgenteChange} style={{ width: '400px', height: '200px' }} />
        <Button variant="outlined" size="small" onClick={passTextToEditor}>SALVA</Button>
      </div>

    </>

  );
});



export default DandoEditor;


