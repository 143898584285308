import React from 'react';



const Privacy = () =>{

    return(
        <div>So I'm Locked in PRIVACY</div>
    );
}


export default Privacy;