import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"

import Translate from "../components/cms/Translate";

import { Container } from '@mui/material';

import Slideshow from './Slideshow';
import Log from './locked/Log';
import Catalogo from './locked/Catalogo';
import Ricerca from './locked/Ricerca';
import Privacy from './locked/Privacy';

import { useContext } from 'react';
import LanguageContext from "./../languageContext";

const Page = () => {

    let params = useParams();
    const pageID = params.pageIdentifica;

    // eslint-disable-next-line no-unused-vars
    const { language, setLanguage } = useContext(LanguageContext);

    //let [searchParams, setSearchParams] = useSearchParams(); //da "react-router-dom" (import { useSearchParams } from "react-router-dom") => mi porta tutte le querystring (NON LO STO PIU USANDO)
    //const pageID = searchParams.get("pagina");

    const [titolo, setTitolo] = useState("");
    const [body, setBody] = useState("");
    const [components, setComponents] = useState([]);


    useEffect(() => {

        console.log("pageID " + pageID);  // <-------- FUNZIONA !!!!!!!!!!!

        setComponents([]); //svuoto array

        fetch("/api/datipagina/" + pageID)
            .then((results) => {
                return results.json();
            })
            .then(data => {

                setTitolo(data[0].titolo);


                if (data[0].locked !== "n/a") { //controllo se c'è LOCKED
                    switch (data[0].locked) {
                        case "log": setBody(() => { return (<Log />); }); break;
                        case "catalogo": setBody(() => { return (<Catalogo />); }); break;
                        case "ricerca": setBody(() => { return (<Ricerca />); }); break;
                        case "privacy": setBody(() => { return (<Privacy />); }); break;
                        default: setBody(() => { return "" })
                    }
                }
                else {
                    var stringa = data[0].body;
                    var ecco = stringa.split(/\[slideShow ID=\b(0?[1-9]|[1-9][0-9]|100)\b\]/g); // con questa regedit ID può essere da 1 a 100 (attenzione (*) il valore ID viene splittato)

                    for (var i = 0; i < ecco.length; i++) { // CERCO SLIDESHOWSSS

                        if (isNaN(ecco[i])) { // questo NON è numero

                            components.push({ componentName: 'Testo', props: { id: i, foo: ecco[i] } });
                        }
                        else { // questo è NUMERO => slideshow (*) 

                            components.push({ componentName: 'Slideshow', props: { id: ecco[i] } });
                        }
                    }

                    //if (data[0].body.indexOf("[slideShow ID=") >= 0) {
                    if (components.length > 0) {

                        setBody(() => {
                            return (
                                <div>
                                    {components.map(({ componentName, props }) => {
                                        if (componentName === "Testo") {
                                            return <div key={"body_" + props.id} dangerouslySetInnerHTML={createHtmlMarkup(props.foo)} />
                                        }
                                        else if (componentName === "Slideshow") {
                                            return <Slideshow key={"slideshow_" + props.id} id={props.id} />;
                                        }
                                        else {
                                            return null;
                                        }
                                    })}
                                </div>
                            );
                        });
                    }
                    else {
                        setBody(() => {
                            return (
                                <div>
                                    <p key={"body_" + pageID} dangerouslySetInnerHTML={createHtmlMarkup(data[0].body)} />
                                </div>
                            );
                        });
                    }
                }


                //aggiorno selezione link pagine
                higlightCurrentPage();

            })
        //(uso il comment successivo per disabilitare warnings su Component dentro UseEffect)
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [pageID]); // useEffect parte solo se si modifica pageID

    function higlightCurrentPage() {
        // il codice sotto indica la pagina corrente, ma:
        // 1) non funziona nel menu mobile
        // 2) non funziona in tutte i link del menu che non sono Page
        
        console.log("indicaPaginaNelMenu");

        var pagine = document.querySelectorAll(".menuUl li");
        for (var i = 0; i < pagine.length; i++) {
            var current = pagine[i].id;
            if (document.getElementById(current) !== null){
                if (pageID === current.replace("menu_mobile_", "").replace("menu_desktop_", "")) {
                    console.log("pagina attiva nel menu " + current);
                    document.getElementById(current).classList.add("selected");
                }
                else{
                    document.getElementById(current).classList.remove("selected");
                }
            }
        }
    }


    function createHtmlMarkup(stringa) { // ->funzione necessaria per utilizzare "dangerouslySetInnerHTML"
        return { __html: stringa };
    }

    return (
        <>
            <Container key={"page_" + pageID} maxWidth="lg" className="pageContainer">
                <h1 key={"titolo_" + pageID}>{titolo}</h1>
                <h5 key={"h5_" + pageID}><Translate text="Identificativo" lang={language} />: {pageID}</h5>
                <div key={"div_" + pageID} className="pageBody">{body}</div>

            </Container>

        </>

    );
}

export default Page;