import { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';
import Slideshow from './components/Slideshow';
import Home from "./components/Home";
import Test from "./components/Test";
import Counter from "./components/Counter";
import Pagine from "./components/cms/Pagine";
import Immagini from "./components/cms/Immagini";
import Page from "./components/Page";

import LanguageContext from "./languageContext";

export default function App() {

    const [language, setLanguage] = useState("it");
    const languageValue = {language, setLanguage}; //questo mi permette di lagare il valore al Context Provider, e al tempo stesso utilizzare useState

    return (
        <LanguageContext.Provider value={languageValue}>
            <ThemeProvider theme={theme}>
                <NavMenu />
                <Routes>
                    <Route path='/' element={<>
                        <Slideshow />
                        <Home />
                    </>} />
                    <Route path='home' element={<>
                        <Slideshow />
                        <Home />
                    </>} />
                    <Route path='counter' element={<Counter />} />
                    <Route path='test' element={<Test />} />
                    <Route path='cms/pagine' element={<Pagine />} />
                    <Route path='cms/immagini' element={<Immagini />} />
                    <Route path='page' element={<Page />}>
                        <Route path=':pageIdentifica' element={<Page />} /> {/*A nested route!*/}
                    </Route>
                </Routes>
                <Footer />
            </ThemeProvider>
        </LanguageContext.Provider>
    )
}

// su Index.html => CSS e FONT per tutto ciò che non è Material UI ||||| con createTheme passo settaggi anche a componenti Material UI
const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans',
            'sans-serif',
        ].join(','),
        fontSize: 14, //default fontSize è 14
        //htmlFontSize: 14,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            //lg: 1200,
            lg: 1400,
            //xl: 1536,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: "#0d624b",
        }
    },
    root: {
        "&::-webkit-scrollbar": {
            width: 7,
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
            outline: `1px solid slategrey`,
        },
    }
});
