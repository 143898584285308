import React from 'react';



const Ricerca = () =>{

    return(
        <div>So I'm Locked in RICERCA</div>
    );
}


export default Ricerca;