import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Container } from '@mui/material';

import DandoEditor from './DandoEditor';

import BuildIcon from '@mui/icons-material/Build';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';



const Pagine = () => {

    // VARIABILI
    const [arrayCategoria, setArrayCategoria] = useState([]);
    const [categoria, setCategoria] = useState('');
    const [titolo, setTitolo] = useState('');
    const [locked, setLocked] = useState('');
    const [lingua, setLingua] = useState('');
    const [linguaImg, setLinguaImg] = useState('');
    const nuovaCategoria = useRef();
    const modificaId = useRef(null);
    const dandoEditorRef = useRef();

    // CARICAMENTO PAGINE ------------------------------------
    const [pagine, setPagine] = useState([]);

    const tutteLePagine = async () => {

        await fetch("api/allpages")
            .then((results) => {
                return results.json();
            })
            .then(data => {
                //setPagine(data);
                setPagine(manipolaArray(data));

                caricaCategorie(data);
            })
    }

    useEffect(() => {
        tutteLePagine(); // questo mi lancia la funzione tutteLePagine onload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    // MODIFICA DATI ---------------------------------------
    const eliminaPagina = (id) => {
        var c = window.confirm("eliminare pagina con ID " + id + "?");
        if (c) {
            fetch("/api/eliminapagina/" + id)
                .then((results) => {
                    tutteLePagine();
                })
        }
    }

    const modificaPagina = (id) => {

        fetch("/api/datipagina/" + id)
            .then((results) => {
                return results.json();
            })
            .then(data => {
                // ci sarà solo 1 valore della lista, quindi posso usare data[0]:

                modificaId.current.value = data[0].id;
                setCategoria(data[0].categoria);
                setTitolo(data[0].titolo);
                setLocked(data[0].locked);
                setLingua(data[0].lingua);
                setLinguaImg("img/" + data[0].lingua + ".gif");

                dandoEditorRef.current.setTheEditorData(data[0].body);
            })

    }

    const handleCellClick = (params) => {
        //console.log("riga ID " + params.id);
        //console.log("valore CELLA " + params.field);

        if (params.field === "elimina") {
            eliminaPagina(params.id);
        }
        else if (params.field === "modifica") {
            modificaPagina(params.id);
        }
        else if (params.field === "controlla") {
            window.location.href = "/page/" + params.id;
        }
    };

    const handleLingue = (event) => {
        setLingua(event.target.value);
        setLinguaImg("img/" + event.target.value + ".gif");
    };

    const handleCategorie = (event) => {
        setCategoria(event.target.value);
    };

    const handleLocked = (event) => {
        setLocked(event.target.value);
    };

    async function caricaCategorie(arrayPagine) {

        setArrayCategoria([]); //reset

        var checkIt = "";   // => i dati pagina vengono passati ordinati per lingua => categoria
        await arrayPagine.forEach((obj, i) => {
            if (checkIt.indexOf("|" + obj.categoria + "|") === -1) {
                setArrayCategoria(prevArray => [...prevArray, obj.categoria])
                checkIt += "|" + obj.categoria + "|";
            }
        })
    }


    // INVIO DATI

    const sendData = () => {

        if (titolo === "" || categoria === "") {
            alert("mancano campi");
        }
        else {

            var dandoEditorText = dandoEditorRef.current.getTheEditorData();

            var data = new FormData();
            data.append("id", modificaId.current.value); //serve se sono in modifica

            if (nuovaCategoria.current.value !== "") { data.append("categoria", nuovaCategoria.current.value); }
            else { data.append("categoria", categoria); }

            data.append("titolo", titolo);
            data.append("body", dandoEditorText);
            data.append("locked", locked);
            data.append("lingua", lingua);

            //invio
            fetch('api/postfromreact',
                {
                    method: "POST",
                    body: data
                })
                .then(response => response.text())
                .then(data => {

                    //reset valori (non sicuro che serva)
                    setTitolo(""); setCategoria(""); nuovaCategoria.current.value = "";
                    setLocked(""); setLingua(""); setLinguaImg("");
                    dandoEditorRef.current.setTheEditorData("");

                    window.scrollTo(0, 0);

                    tutteLePagine();
                });
        }

    }







    // IMPAGINAZIONE su RETURN -------------------------------------------------------

    return (
        <>
            <Container maxWidth="lg" sx={{ margin: "0 auto" }}>
                <h1>Gestione PAGINE</h1>

                <div style={{ width: '100%', height: 370, backgroundColor: '#f8f8f8' }}>
                    <DataGrid rows={pagine} columns={columns} pageSize={5} rowsPerPageOptions={[5]} onCellClick={handleCellClick} />
                </div>

                <div className='formBlock'>

                    <div style={{ width: '94px', margin: '0 0 20px 0' }}>
                        <img src={linguaImg} alt="" className='linguaFlagImg' />
                        <FormControl fullWidth>
                            <InputLabel size="small">Lingua</InputLabel>
                            <Select value={lingua} label="Lingua" onChange={handleLingue} size="small" autoWidth={true}>
                                <MenuItem value="it">it</MenuItem>
                                <MenuItem value="en">en</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <FormControl style={{ width: '210px', margin: '0 10px 0 0' }}>
                        <InputLabel size="small">Categoria</InputLabel>
                        <Select value={categoria} label="Categoria" onChange={handleCategorie} size="small" autoWidth={true}>
                            {arrayCategoria.map((category, index) => (
                                <MenuItem key={index} value={category}>
                                    {category}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField inputRef={nuovaCategoria} label="Nuova Categoria" size="small" variant="outlined" /><br /><br />
                    <TextField label="Titolo" sx={{ width: "100%", maxWidth: "600px" }} value={titolo} variant="outlined" onChange={(event) => { setTitolo(event.target.value) }} />
                    <br /><br />
                    <FormControl style={{ width: '210px', margin: '0 10px 0 0' }}>
                        <InputLabel size="small">Locked</InputLabel>
                        <Select value={locked} label="Locked" onChange={handleLocked} size="small" autoWidth={true}>
                            <MenuItem key="lk_0" value="">----</MenuItem>
                            <MenuItem key="lk_1" value="log">log</MenuItem>
                            <MenuItem key="lk_2" value="catalogo">catalogo</MenuItem>
                            <MenuItem key="lk_3" value="ricerca">ricerca</MenuItem>
                            <MenuItem key="lk_4" value="news">news</MenuItem>
                            <MenuItem key="lk_5" value="privacy">privacy</MenuItem>
                        </Select>
                    </FormControl>
                    <br /><br />

                    <DandoEditor ref={dandoEditorRef} />

                    <br /><br />

                    <Button variant="contained" size="large" onClick={sendData}>Invia DATI</Button>

                    <input type="hidden" ref={modificaId} />
                </div>
            </Container>
        </>
    );




}


// utilities

const manipolaArray = (array) => {
    const newArray = [];
    array.forEach((obj, i) => {
        obj.categoria = obj.categoria.toString().replace(/n\/a/g, "");
        obj.locked = obj.locked.toString().replace(/n\/a/g, "");

        newArray.push(obj);
    })
    return newArray;
};



// DATAGRID -----------------------------------------
const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'categoria', headerName: 'Categoria', flex: 1.5 },
    { field: 'titolo', headerName: 'Titolo', flex: 2.5 },
    {
        field: 'locked',
        headerName: 'Locked',
        flex: 1,
    },
    {
        field: 'body',
        headerName: 'Body',
        description: 'Attenzione puoi unire dati su unica colonna - value getter.',
        sortable: false,
        flex: 2.5,
    },
    { field: 'lingua', headerName: 'Lingua', flex: 1 },
    { field: 'pubblica', headerName: 'OnLine', flex: 1 },

    { field: "controlla", headerName: '', flex: 0.5, cellClassName: 'dgControlla', sortable: false, filterable: false, renderCell: () => (<PlayCircleIcon />), align: "center" },
    { field: "modifica", headerName: '', flex: 0.5, cellClassName: 'dgModifica', sortable: false, filterable: false, renderCell: () => (<BuildIcon />), align: "center" },
    { field: 'elimina', headerName: '', flex: 0.5, cellClassName: 'dgElimina', sortable: false, filterable: false, renderCell: () => (<RemoveCircleIcon />), align: "center" },
];


export default Pagine;