import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Container } from '@mui/material';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import BuildIcon from '@mui/icons-material/Build';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import Slideshow from '../Slideshow';


const Immagini = () => {

    // CARICAMENTO PAGINE ------------------------------------
    const [tutteLeImmagini, setTutteLeImmagini] = useState([]);
    const [fileTitle, setFileTitle] = useState('');
    const modificaId = useRef(null);
    const [imgSrc, setImagSrc] = useState();
    const [fileSelected, setFileSelected] = useState();

    const saveFileSelected = (e) => {
        setFileSelected(e.target.files[0]);
    };

    const getAllImages = () => {

        fetch("api/allImages")
            .then((results) => {
                return results.json();
            })
            .then(data => {
                setTutteLeImmagini(manipolaArray(data));
            })
    }

    useEffect(() => {

        var logged = localStorage.getItem("logged");

        if (logged != null) {
            console.log("logged ID: " + logged);
            getAllImages(); // questo mi lancia la funzione tutteLePagine onload
        }
        else {
            alert("Non sei loggato CAZZO");
        }

    }, []);

    const handleCellClick = (params) => {

        if (params.field === "elimina") {
            eliminaImmagine(params.id);
        }
        else if (params.field === "modifica") {
            preparaModifica(params.id);
        }
        else if (params.field === "addToSlideshow") {
            addSlide(params.id);
        }
    };


    const importFile = async (e) => {
        const formData = new FormData();
        formData.append("Id", modificaId.current.value); //serve se sono in modifica
        formData.append("Title", fileTitle);
        formData.append("ImageFile", fileSelected);
        formData.append("ImageName", imgSrc);

        //invio
        fetch('api/uploadImage',
            {
                method: "POST",
                body: formData
            })
            .then((results) => {
                return results.json();
            })
            .then(data => {
                console.log("uploading " + data.imageName)
                getAllImages();
            })
    };

    const eliminaImmagine = (id) => {
        var c = window.confirm("eliminare immagine con ID " + id + "?");
        if (c) {
            fetch("/api/eliminaImmagine/" + id)
                .then((results) => {
                    getAllImages();
                })
        }
    }

    const preparaModifica = (id) => {

        fetch("api/preparaModifica/" + id)
            .then((results) => {
                return results.json();
            })
            .then(data => {
                // ci sarà solo 1 valore della lista, quindi posso usare data[0]:
                modificaId.current.value = data[0].id;
                setFileTitle(data[0].title.replace("n/a", ""));

                setImagSrc("/images/" + data[0].imageName);
            })

    }

    //----------------
    //SLIDESHOW

    const [slideShow, setSlideShow] = useState('');
    const [tipo, setTipo] = useState('slideShow');
    const [imageListSettings, setImageListSettings] = useState("standard");
    const [colonneImageList, setColonneImageList] = useState("3");
    const [mostraTitolo, setMostraTitolo] = useState(false);
    const [slides, setSlides] = useState('');
    const [showSlides, setShowSlides] = useState('');
    const [allSlideShow, setAllSlideShow] = useState('');
    const modificaSlideShowId = useRef(null);
    const [checkedMostraLista, setCheckedMostraLista] = useState(false);

    useEffect(() => {

        getAllSlideshow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllSlideshow = () => {

        fetch("api/allSlideShow")
            .then((results) => {
                return results.json();
            })
            .then(data => {

                setAllSlideShow(data.map((current, i) => {
                    return (
                        <div key={'ss_div_' + i} className='slideShowData'>
                            <BuildIcon key={'ss_m_' + i} onClick={(event) => { modificaSlideshow(current.id) }} />
                            <RemoveCircleIcon key={'ss_d_' + i} onClick={(event) => { eliminaSlideshow(current.id) }} />
                            <span key={'ss_span_' + i} >{current.title}</span>
                            <u key={'ss_u_' + i}>{(current.showTitle === "true") ? "+ titoli" : ""}</u>
                            {(current.type === "imageList") ? " LISTA: " + current.settings + " 🢒 " : " Slideshow 🢒 "}
                            {"[slideShow ID=" + current.id + "]"}
                            <br />
                            <Slideshow key={'ss_' + i} id={current.id} mode="CMS_preview" /> {/*questo recupera le immagini dello slideshow dal componente slideshow */}
                        </div>
                    );
                }));
            })
    }


    const handleTipo = (event) => {
        if (event.target.checked === true) { setTipo("imageList"); setCheckedMostraLista(true); }
        else { setTipo("slideShow"); setCheckedMostraLista(false); }
    };

    const handleTipo2 = (event) => {
        if (event.target.checked === true) { setMostraTitolo(true); }
        else { setMostraTitolo(false); }
    };


    const addSlide = async (id) => { // per vedere in tempo reale cosa aggiungo ad un nuovo slideshow (con "async" e "await" ad ogni ciclo attendo il risultato)

        let currentSlides = slides;
        currentSlides += id + "|";

        setSlides(currentSlides);

        //tutto il resto è per vedere le immagini che sto aggiungendo (ma il dato è "slides")
        var provvisorioArray = [];
        const esplora = currentSlides.split('|');
        for (let i = 0; i < esplora.length - 1; i++) {

            await fetch("api/preparaModifica/" + esplora[i])
                .then((results) => { return results.json(); })
                .then(data => {

                    provvisorioArray.push({ //carico su nuovo array, con coppia id/img
                        id: data[0].id,
                        img: data[0].imageName
                    });
                    if (i === esplora.length - 2) { // faccio vedere le immagini solo quando son sicuro di aver finito il fetch
                        showSlidesNow(provvisorioArray);
                    }
                })
        }
    }

    function showSlidesNow(provvisorioArray) {
        setShowSlides(provvisorioArray.map((current, i) => {
            return (
                <img key={'prov_' + i} src={"/images/square_" + current.img} alt="" />
            );
        }));
    }

    function saveSlideshow() {
        console.log("saveSlideshow");

        const formData = new FormData();
        formData.append("Id", modificaSlideShowId.current.value); //serve se sono in modifica
        formData.append("Title", slideShow);
        formData.append("Images", slides);
        formData.append("Type", tipo);
        formData.append("Settings", imageListSettings + "|" + colonneImageList);
        formData.append("ShowTitle", mostraTitolo.toString());

        //invio
        fetch('api/saveSlideShow', { method: "POST", body: formData })
            .then(response => response.text())
            .then(data => {
                console.log("saveSlideShow " + data)

                //reset
                setSlideShow(''); setSlides(''); setTipo('slideShow'); setImageListSettings('standard'); setShowSlides(''); setCheckedMostraLista(false); setMostraTitolo(false);

                //aggiorno elenco
                getAllSlideshow();
            });
    }

    const eliminaSlideshow = (id) => {

        var c = window.confirm("eliminare slideshow con ID " + id + "?");
        if (c) {
            fetch("/api/eliminaSlideShow/" + id).then((results) => { getAllSlideshow(); })
        }
    }


    function modificaSlideshow(id) { // impagino modifica per slideshow

        modificaSlideShowId.current.value = id;

        fetch("api/slideShowData/" + id)
            .then((results) => { return results.json(); })
            .then(data => {

                //tutti i dati slideshow => data.id    data.title   data.images   data.type   data.settings    data.showTitle   data.imagesList
                setTipo(data.type);
                setSlideShow(data.title);
                setSlides(data.images);
                setShowSlides(data.imagesList.map((current, i) => {
                    return (
                        <img key={'prov_' + i} src={"" + current.src.replace("/medium_", "/").replace("/images/", "/images/square_")} alt="" />
                    );
                }));
                if (data.settings !== null) {
                    setImageListSettings(data.settings.toString().split("|")[0]);
                    setColonneImageList(parseInt(data.settings.toString().split("|")[1]));
                }
                (data.showTitle === "true") ? setMostraTitolo(true) : setMostraTitolo(false);
                (data.type === "imageList") ? setCheckedMostraLista(true) : setCheckedMostraLista(false);

                const element = document.getElementById('slideshowSection');
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "end" }); // 👇 Will scroll smoothly to the top of the next section
                }
            })
    }

    //----------

    return (
        <>
            <Container maxWidth="lg" sx={{ margin: '0 auto' }}>
                <h1>Gestione Immagini</h1>

                <div style={{ height: 370, width: '100%', backgroundColor: '#f8f8f8' }}>
                    <DataGrid rows={tutteLeImmagini} columns={columns} pageSize={5} rowsPerPageOptions={[5]} onCellClick={handleCellClick}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'desc' }],
                            },
                        }}
                    />
                </div>

                <div style={{ margin: "60px 0 0 0" }}>
                    <TextField label="Titolo" value={fileTitle} variant="outlined" onChange={(event) => { setFileTitle(event.target.value) }} sx={{ width: "100%" }} />
                    <br />
                    <span style={{fontSize: "10px"}}>Utilizza | per separare titolo e sottotitolo</span>
                    <br /><br />
                    <TextField type="file" sx={{ margin: "0 20px 0 0" }} onChange={saveFileSelected} />
                    <Button variant="contained" size="large" onClick={importFile}>INVIA</Button>
                </div>

                <input type="hidden" ref={modificaId} />

                <div style={{ margin: "10px 0 0 0", width: '100%' }}>
                    <img src={imgSrc} alt="" style={{ maxWidth: '100%' }} />
                </div>

                <hr style={{ margin: "10px 0 30px 0", width: '100%' }} />

                <div id="slideshowSection" className="slideshowBlock">
                    <TextField label="Slideshow titolo" value={slideShow} variant="outlined" onChange={(event) => { setSlideShow(event.target.value) }} />
                    <Button variant="contained" onClick={saveSlideshow} sx={{ margin: "0 10px" }}>SALVA SLIDESHOW</Button> {showSlides}

                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={mostraTitolo} onChange={handleTipo2} />} label="Mostra titolo nelle Slides" />
                        <FormControlLabel control={<Checkbox checked={checkedMostraLista} onChange={handleTipo} />} label="Crea come Lista Immagini" />
                        <FormControl sx={{ display: (tipo === "imageList") ? "block" : "none" }}>
                            <InputLabel size="small">Settings</InputLabel>
                            <Select label="Settings" value={imageListSettings} defaultValue="standard" size="small" onChange={(e) => { setImageListSettings(e.target.value); }} sx={{ width: "160px" }}>
                                <MenuItem value="standard">standard</MenuItem>
                                <MenuItem value="quilted">quilted</MenuItem>
                                <MenuItem value="woven">woven</MenuItem>
                                <MenuItem value="masonry">masonry</MenuItem>
                            </Select>
                            <TextField label="N° colonne" value={colonneImageList} type="number" onChange={(event) => { setColonneImageList(event.target.value) }} size="small" sx={{ width: "110px", margin: "0 20px" }} />
                        </FormControl>
                    </FormGroup>

                    <br /><br />
                    {allSlideShow}

                    <input type="hidden" ref={modificaSlideShowId} />
                </div>

            </Container>
        </>
    );
}


// utilities

const manipolaArray = (array) => {
    const newArray = [];
    array.forEach((obj, i) => {
        //obj.modifica = "Mod";
        //obj.elimina = "X";

        var newTitle = obj.title;

        if (obj.title.indexOf("|") >= 0) {
            newTitle = obj.title.split('|')[0].trim().replace("n/a", "");
            newTitle += " => " + obj.title.split('|')[1].trim();
        }

        obj.title = newTitle;

        //var currentImg = "<img src='/images/" + obj.imageName + "' alt=''/>";
        //obj.imageName = currentImg;

        newArray.push(obj);
    })
    return newArray;
};


// DATAGRID -----------------------------------------
const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'title', headerName: 'Titolo', flex: 2.5 },
    { field: 'imageName', headerName: 'Immagine', flex: 2.5, renderCell: (params) => <img key={"dgimg_" + params.id} src={"/images/square_" + params.value} alt="" />, cellClassName: 'datagridImage' },
    { field: 'addToSlideshow', headerName: 'Slideshow', flex: 1, cellClassName: 'dgControlla', sortable: false, filterable: false, renderCell: (params) => (<AddAPhotoIcon key={"dga_" + params.id} />), align: "center" },
    { field: "modifica", headerName: '', flex: 0.5, cellClassName: 'dgModifica', sortable: false, filterable: false, renderCell: (params) => (<BuildIcon key={"dgm_" + params.id} />), align: "center" },
    { field: 'elimina', headerName: '', flex: 0.5, cellClassName: 'dgElimina', sortable: false, filterable: false, renderCell: (params) => (<RemoveCircleIcon key={"dge_" + params.id} />), align: "center" },
];

export default Immagini;