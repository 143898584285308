import React from 'react';



const Log = () => {

    return (
        <>
        <div>So I'm Locked in LOG = sono componente</div>
        <div>e posso essere usato tipo i file .ascx su Web Builder</div>
        </>
    );
}


export default Log;