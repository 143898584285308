import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { createTheme, ThemeProvider } from '@mui/material';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';

// su Index.html => CSS e FONT per tutto ciò che non è Material UI
// con createTheme passo settaggi a componenti Material UI
const theme = createTheme({
  typography: {
    fontFamily: [
      'Lato',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      //xl: 1536,
      xl: 1920,
    },
  },
});

function App(props) {

  const displayName = props.name;

  return (

    <ThemeProvider theme={theme}>
      <NavMenu />
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;

        })}
      </Routes>
      <Footer />
    </ThemeProvider>

  );
}



export default App;
