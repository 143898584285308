const Translate = (props) => {

    let tradotto = props.text;

    if (props.lang === "en") {
        switch (props.text) {
            case 'Identificativo': tradotto= "ID Identification"; break;
            case 'Impostazioni': tradotto= "Settings"; break;
            default: tradotto = props.text;
          }
    }

    return tradotto;
}


export default Translate;