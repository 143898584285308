import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';



const Loggin = () => {

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [loading, setLoading] = useState('');

    useEffect(() => {
        var logged = localStorage.getItem("logged");
        if (logged != null) {
            setUserId(logged);
            setUserName(localStorage.getItem("username"));
        }
    }, []);

    const doLog = () => {
        if (email !== "" && pwd !== "") {
            setLoading(" ...attendere");

            var data = new FormData();
            data.append("Email", email);
            data.append("Password", pwd);
            //invio
            fetch('api/doLog', { method: "POST", body: data })
                .then((results) => { return results.json(); })
                .then(data => {
                    console.log("data " + data.cognome);
                    setUserId(data.id);
                    setUserName(data.nome + " " + data.cognome);

                    //sessionStorage.setItem("logged", data.id);
                    //sessionStorage.setItem("username", data.nome + " " + data.cognome);

                    localStorage.setItem("logged", data.id);
                    localStorage.setItem("username", data.nome + " " + data.cognome);

                    //var item_value = sessionStorage.getItem("item_key");

                    setLoading("");
                })
        }
        else {
            alert("dati mancanti");
        }
    }

    const logOut = () => {
        setUserId("");
        setEmail(""); setPwd(""); setUserName("");

        localStorage.removeItem("logged");
        localStorage.removeItem("username");
    }


    const [popupClass, setPopupClass] = useState("");
    const reducePopUp = () => {
        if (popupClass == "reducePopUp") {
            setPopupClass("");
        }
        else {
            setPopupClass("reducePopUp");
        }

    }


    if (userId != "") {
        return (

            <div id="loggin" className={popupClass}>
                <CloseIcon onClick={reducePopUp} />
                <div>{userName}</div>
                <Button variant="outlined" size="small" onClick={logOut}>LOG OUT</Button>
            </div>

        );
    }
    else {
        return (

            <div id="loggin" className={popupClass}>
                <CloseIcon onClick={reducePopUp} />
                <p>ADMIN</p>
                <div><TextField label="Email" size="small" onChange={(event) => { setEmail(event.target.value) }} /></div>
                <div><TextField label="Password" type="password" size="small" onChange={(event) => { setPwd(event.target.value) }} /></div>
                <Button variant="contained" size="small" onClick={doLog}>LOG IN</Button>{loading}
            </div>

        );
    }
}



export default Loggin;